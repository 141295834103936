import React from 'react';
import loadable from '@loadable/component';
import { graphql } from 'gatsby';

import { Layout } from '@components/layout/desktop/Layout';
import { Protected } from '@components/auth';
import { IsNotDesktop } from '@components/layout/desktop/IsNotDesktop';

const PostarVaga = loadable(() => import('@desktop/vagas/PostarVaga'));

function Index(props) {
  return (
    <Protected>
      <IsNotDesktop>
        <Layout {...props}>
          <PostarVaga {...props} />
        </Layout>
      </IsNotDesktop>
    </Protected>
  );
}

export default Index;

export const postarPlantaoQuery = graphql`
  {
    imageSharp {
      fixed(base64Width: 200) {
        src
        originalName
        base64
      }
    }
  }
`;
